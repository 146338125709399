import { ReactNode } from 'react';
import Balancer from 'react-wrap-balancer';
import cn from 'classnames';

export interface HeadingTextProps
	extends Omit<React.HTMLProps<HTMLHeadingElement>, 'size' | 'className'> {
	testId?: string;
	children: ReactNode;
	tag: HeadingOptions;
	size?: HeadingOptions; // Size is for when you need a visual h1 but a h2 tag
	balanced?: boolean;
	className?: string;
}

type HeadingOptions = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const HeadingText = (props: HeadingTextProps) => {
	const {
		testId = 'HeadingText',
		children,
		tag,
		size,
		balanced,
		className,
		...attributes
	} = props;
	const Tag = tag;

	// PLEASE READ
	// The only classes that should ever be added or change here are things directly related to
	// font sizing
	// letter spacing
	// text-color changes
	// do
	// not
	// put padding or margins in here so help me
	return (
		<Tag
			data-testid={testId}
			className={cn(
				{
					'text-center text-4xl font-bold tracking-tight lg:text-6xl':
						(size ?? tag) === 'h1',
				},
				{
					'text-center text-3xl font-semibold tracking-tighter lg:text-5xl':
						(size ?? tag) === 'h2',
				},
				{
					'text-center text-2xl font-bold tracking-tight lg:text-4xl':
						(size ?? tag) === 'h3',
				},
				{
					'text-left text-xl font-semibold tracking-tight lg:text-2xl':
						(size ?? tag) === 'h4',
				},
				'text-black',
				className,
			)}
			{...attributes}
		>
			{balanced ? <Balancer>{children}</Balancer> : children}
		</Tag>
	);
};

export default HeadingText;
